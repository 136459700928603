import React from "react";
import { graphql } from "gatsby";
import TemplatesList from "@views/TemplatesList";
import Layout from "../ui/layout";

import { TemplatesViewQueryQuery } from "@gen/gatsby-graphql";
import techData from "@data/tech";

export const categories = {
  PORTFOLIO: "Portfolio",
  STORE: "eCommerce",
  DASHBOARD: "Dashboard",
  BLOG: "Blog",
  LANDING: "Landing page",
  KITS: "UI Kits",
};

interface TemplatesPageProps {
  data: TemplatesViewQueryQuery;
}

const TemplatesPage: React.FC<TemplatesPageProps> = props => {
  const templates = props.data.allTemplate.edges.map(
    (item: any) => item.node.data,
  );

  const { category, tech } = props?.pathContext || {};

  const techLabel =
    (techData?.[tech?.toUpperCase()]?.text !== "React" &&
      techData?.[tech?.toUpperCase()]?.text) ||
    "";
  const categoryLabel = categories?.[category] || "";

  const seoTitle = !tech
    ? `${templates.length || 10}+ React ${categoryLabel} Templates`
    : `React ${techLabel} ${categoryLabel} Templates`;

  const seoDescription = !tech
    ? `Reactemplates. Search through a variety of React ${categoryLabel} that contains technologies like styled-components, Typescript, Gatsby, Next.js etc`
    : `Multiple React ${techLabel} ${categoryLabel} Templates created following best practices and focused on making developers life easier. React ${techLabel} template - Reactemplates`;

  return (
    <Layout
      header={{ color: "black" }}
      seo={{
        title: seoTitle,
        description: seoDescription,
      }}
      hasFooter={false}
    >
      <TemplatesList
        templates={templates}
        category={category || "all"}
        tech={tech || ""}
      />
    </Layout>
  );
};

export const query = graphql`
  query TemplatesViewQuery($category: String, $tech: [String]) {
    allTemplate(
      filter: {
        data: {
          description: {
            category: { eq: $category }
            tech: { in: $tech }
          }
        }
      }
    ) {
      edges {
        node {
          id
          data {
            slug
            name
            description {
              bigDescription
              shortDescription
              tech
              features {
                key
                text
              }
              category
            }
            price {
              initialPrice
              currentPrice
            }
            thumbnailImages {
              thumbnail {
                childImageSharp {
                  fluid(maxWidth: 560, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default TemplatesPage;
